import React from "react"
import { loadReCaptcha } from 'react-recaptcha-v3'
import Keys from '../constants/keys'

class LoadRecaptcha extends React.Component {
  render() {return null }
  componentDidMount() {
    if(typeof window != 'undefined' && !window.grecaptcha) {
      loadReCaptcha(Keys.recaptcha_site_key);
    }
  }
}

export default LoadRecaptcha
